import React, {useEffect, useState} from 'react';

import {
    FullscreenLayout,
    CallToAction,
    Icon,
    IconTypes,
} from '@pexip/components';

import {MeeContext} from '../contexts/mee.context';
import {logger} from '../logger';
import {router} from '../router';
import {TestId} from '../../test/testIds';
import {useAssertedContext} from '../hooks/useAssertedContext';

export const Create: React.FC = () => {
    const mee = useAssertedContext(MeeContext);
    const [abortController] = useState(new AbortController());
    const create = async () => {
        try {
            const res = await mee.create({abortController});
            router.history.push(`/${res.data.id}`);
        } catch (error) {
            logger.error(
                {
                    error: error instanceof Error && error.message,
                },
                `Can't create a meetingId`,
            );
        }
    };

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    return (
        <FullscreenLayout>
            <CallToAction
                enhancerStart={<Icon source={IconTypes.IconAdd} />}
                subTitle="For quick chats or get-togethers"
                title="Start a meeting"
                onClick={create}
                data-testid={TestId.ButtonCreate}
            />
        </FullscreenLayout>
    );
};
