import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {IconTypes, Panel, Select, ThemeProvider, Text} from '@pexip/components';
import {
    BreakoutRoomsPanelHeader as PanelHeader,
    StreamQuality,
} from '@pexip/media-components';

import {useConfig} from '../../contexts/config.context';
import {useAssertedContext} from '../../hooks/useAssertedContext';
import {MeeContext} from '../../contexts/mee.context';

export const Quality: React.FC<{
    onBackClick: () => void;
}> = ({onBackClick}) => {
    const {t} = useTranslation();
    const mee = useAssertedContext(MeeContext);
    const [streamQuality, setStreamQuality] = useConfig('streamQuality');

    return (
        <Panel
            colorScheme="light"
            style={{width: 380}}
            headerPadding="none"
            headerContent={
                <ThemeProvider colorScheme="light">
                    <PanelHeader
                        title={t('settings.quality', 'Quality')}
                        onBackClick={onBackClick}
                    />
                </ThemeProvider>
            }
        >
            <ThemeProvider colorScheme="light">
                <Select
                    className="mt-1 mb-1"
                    iconType={IconTypes.IconBandwidth}
                    isFullWidth
                    label={t('settings.video-quality', 'Video quality')}
                    options={[
                        {
                            id: StreamQuality.Low,
                            label: t('quality.low', 'Low'),
                        },
                        {
                            id: StreamQuality.Medium,
                            label: t('quality.medium', 'Medium'),
                        },
                        {
                            id: StreamQuality.High,
                            label: t('quality.high', 'High'),
                        },
                    ]}
                    onValueChange={(id: string) => {
                        setStreamQuality(id as StreamQuality, true);
                        mee.refreshStreams();
                    }}
                    sizeModifier="small"
                    value={streamQuality}
                />
                <Text>
                    <Trans key="settings.quality-desc">
                        This setting affects the video quality of this meeting.
                        Adjust to a lower quality if your internet connection is
                        slow.
                    </Trans>
                </Text>
            </ThemeProvider>
        </Panel>
    );
};
