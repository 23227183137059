import React from 'react';

import {createRoutesHook, useRouter} from './router';
import {Create} from './pages/Create.page';
import {Meeting} from './pages/Meeting.page';
import {AutoCreate} from './pages/AutoCreate.page';

const useRoutes = createRoutesHook([
    {
        exact: true,
        path: '/',
        node: AutoCreate,
    },
    {
        path: '/:id',
        node: Meeting,
    },
    {
        exact: true,
        path: '/create',
        node: Create,
    },
]);

export const App: React.FC = () => {
    useRouter();
    return <>{useRoutes()}</>;
};
