import {createLogger} from '@pexip/logger';
import {setLogger as setMeeSdkLogger} from '@pexip/mee-sdk';
import {setLogger as setSignalLogger} from '@pexip/signal';
import {setLogger as setPeerConnectionLogger} from '@pexip/peer-connection';
import {setLogger as setMediaComponentsLogger} from '@pexip/media-components';
import {setLogger as setMediaControlLogger} from '@pexip/media-control';
import {setLogger as setMediaLogger} from '@pexip/media';
import {setLogger as setMeeApiLogger} from '@pexip/mee-api';
import {setLogger as setSocketLogger} from '@pexip/socket-manager';

export const rootLogger = createLogger({
    fileName: 'mee',
});

export const logger = rootLogger.child({name: 'mee'});
setMeeSdkLogger(rootLogger.child({name: 'mee-sdk'}));
setSignalLogger(rootLogger.child({name: 'signal'}));
setPeerConnectionLogger(rootLogger.child({name: 'peer-connection'}));
setMediaComponentsLogger(rootLogger.child({name: 'media-components'}));
setMediaLogger(rootLogger.child({name: 'media'}));
setMediaControlLogger(rootLogger.child({name: 'media-control'}));
setMeeApiLogger(rootLogger.child({name: 'mee-api'}));
setSocketLogger(rootLogger.child({name: 'socket-manager'}));

window.pexDebug = {
    ...window.pexDebug,
    dumpLog: rootLogger.downloadLog,
    get logEvents() {
        return rootLogger.logEvents;
    },
};
