import {Backoff} from '@pexip/utils';
import type {TransceiverConfig} from '@pexip/peer-connection';
import {createSignal} from '@pexip/signal';
import type {
    ForbiddenError,
    InternalServerError,
    MEEWebsocketMessageTypesUnion,
    NotModifiedError,
    RosterUpdate,
    ServiceUnavailableError,
    UnauthorizedError,
    UnknownError,
} from '@pexip/mee-api';
import {BadRequestError, GoneError, NotFoundError} from '@pexip/mee-api';
import {createSocketSignals as createSS} from '@pexip/socket-manager';

import {BACKOFF_BASE_SETTINGS, MAX_RECONNECT_ATTEMPTS} from './constants';
import {logger} from './logger';
import type {MeeError, MeeSignals} from './types';

export const createMeeSignals = () =>
    ({
        onRosterUpdate: createSignal<RosterUpdate['participants']>({
            name: 'mee:onRosterUpdate',
        }),
        onReconnecting: createSignal({
            name: 'mee:reconnecting',
        }),
        onReconnected: createSignal({
            name: 'mee:reconnecting',
        }),
        onRemoteStreams: createSignal<TransceiverConfig>({
            name: 'mee:onRemoteStreams',
        }),
        onError: createSignal<MeeError>({
            name: 'mee:onError',
        }),
    }) satisfies MeeSignals;

export const createSocketSignals = () =>
    createSS<MEEWebsocketMessageTypesUnion>();

export const createRecvTransceivers = (
    kind: 'audio' | 'video',
    length: number,
    content?: string,
) =>
    Array.from(
        {length},
        () =>
            ({
                direction: 'recvonly',
                kindOrTrack: kind,
                content,
            }) as const,
    );

export const sleep = (ms: number) =>
    new Promise(resolve => setTimeout(resolve, ms));

const ANONYMOUS_FN = 'Anonymous';

const getName = <T>(observer: () => Promise<T>) => {
    return observer.name || ANONYMOUS_FN;
};

export const retriable = async <T>(
    fn: () => Promise<T>,
    backoff = new Backoff(BACKOFF_BASE_SETTINGS),
    retries = MAX_RECONNECT_ATTEMPTS,
): Promise<T> => {
    try {
        return await fn();
    } catch (error) {
        if (error instanceof Error && error.name === 'AbortError') {
            logger.warn({error}, `${getName(fn)} aborted`);
            throw error;
        }

        if (retries === 0) {
            logger.error({error}, `${getName(fn)} failed`);
            throw error;
        }

        if (error instanceof BadRequestError) {
            logger.warn(
                {error},
                `${getName(fn)} has bad request. No point of retrying`,
            );
            throw error;
        }

        if (error instanceof NotFoundError) {
            logger.warn(
                {error},
                `${getName(fn)} not found. No point of retrying`,
            );
            throw error;
        }

        if (error instanceof GoneError) {
            logger.warn({error}, `${getName(fn)} gone. No point of retrying`);
            throw error;
        }

        logger.error({error, retries}, `retrying ${getName(fn)}`);
        await sleep(backoff.duration());
        return await retriable(fn, backoff, retries - 1);
    }
};

export const isSendConfig = (config: TransceiverConfig) =>
    config.direction === 'sendonly';
export const isInactiveConfig = (config: TransceiverConfig) =>
    config.direction === 'inactive';
export const isRecvConfig = (config: TransceiverConfig) =>
    config.direction === 'recvonly';
export const isMainConfig = (config: TransceiverConfig) =>
    config.content === 'main';
export const isPresoConfig = (config: TransceiverConfig) =>
    config.content === 'slides';
export const isAudioConfig = (config: TransceiverConfig) =>
    config.kind === 'audio';
export const isVideoConfig = (config: TransceiverConfig) =>
    config.kind === 'video';
export const isMainSendConfig = (config: TransceiverConfig) =>
    [isMainConfig, isSendConfig].every(fn => fn(config));
export const isPresoInactiveConfig = (config: TransceiverConfig) =>
    [isPresoConfig, isInactiveConfig].every(fn => fn(config));
export const isPresoRecvConfig = (config: TransceiverConfig) =>
    [isPresoConfig, isRecvConfig].every(fn => fn(config));
export const isPresoSendConfig = (config: TransceiverConfig) =>
    [isPresoConfig, isSendConfig].every(fn => fn(config));
export const isPresoVideo = (config: TransceiverConfig) =>
    [isPresoConfig, isVideoConfig].every(fn => fn(config));

export class WebsocketError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'WebsocketError';
    }
}

export class ResourceUnavailableError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ResourceUnavailableError';
    }
}

export class MeetingFullError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'MeetingFullError';
    }
}

export {
    BadRequestError,
    UnauthorizedError,
    ForbiddenError,
    GoneError,
    NotFoundError,
    ServiceUnavailableError,
    InternalServerError,
    NotModifiedError,
    UnknownError,
};
