import React, {useEffect} from 'react';

import {FullscreenSpinner} from '@pexip/components';

import {MeeContext} from '../contexts/mee.context';
import {logger} from '../logger';
import {router} from '../router';
import {useAssertedContext} from '../hooks/useAssertedContext';

export const AutoCreate: React.FC = () => {
    const mee = useAssertedContext(MeeContext);

    useEffect(() => {
        const abortController = new AbortController();
        const create = async () => {
            try {
                const res = await mee.create({abortController});
                router.history.push(`/${res.data.id}`);
            } catch (error) {
                logger.error(
                    {
                        error: error instanceof Error && error.message,
                    },
                    `Can't create a meetingId`,
                );
            }
        };

        void create();

        return () => {
            abortController.abort();
        };
    }, [mee]);

    return <FullscreenSpinner />;
};
