import type {AddNewCandidate} from '@pexip/mee-api';
import type {MediaInit} from '@pexip/peer-connection';
import {
    createPCSignals,
    createEventQueue,
    createMainPeerConnection,
} from '@pexip/peer-connection';

import {logger} from './logger';
import type {MeeSignals, SocketManager, SocketSignals} from './types';
import {isMainConfig} from './utils';

const toIceCandidate = (ice: RTCIceCandidate): AddNewCandidate => ({
    candidate: ice.candidate,
    mid: ice.sdpMid ?? '0',
    ufrag: ice.usernameFragment ?? '',
    pwd: '', // this should be optional in the `AddNewCandidate` type
});

export const createCall = ({
    meeSignals,
    socketSignals,
    socket,
    mediaInits,
    abortController,
}: {
    meeSignals: MeeSignals;
    socketSignals: SocketSignals;
    socket: SocketManager;
    mediaInits: MediaInit[];
    abortController?: AbortController;
}) => {
    const pcSignals = createPCSignals([
        'onIceCandidate',
        'onRemoteStreams',
        'onConnectionStateChange',
    ]);
    const pc = createMainPeerConnection(pcSignals, {
        mediaInits,
        rtcConfig: {
            bundlePolicy: 'max-bundle',
        },
    });

    const handleAbort = () => {
        if (pc.peer.connectionState === 'connecting') {
            close();
        }
    };

    const sendCandidate = (candidate: RTCIceCandidate | null) => {
        if (!candidate) {
            logger.debug('End of candidates');
            return;
        }

        socket.send({
            type: 'add_new_candidate',
            ...toIceCandidate(candidate),
        });
    };

    const outgoingICECandidateQueue = createEventQueue(sendCandidate);

    const releaseOutGoingCandidateBuffer = () => {
        outgoingICECandidateQueue.buffering = false;
        const candidatesFlushed = outgoingICECandidateQueue.flush();
        logger.debug(
            {outgoingCandidates: candidatesFlushed},
            'release buffered outgoing candidates',
        );
    };

    abortController?.signal.addEventListener('abort', handleAbort, {
        once: true,
    });

    let detachSocketSignals = [
        socketSignals.onMessage.add(msg => {
            switch (msg.type) {
                case 'media_offer':
                    releaseOutGoingCandidateBuffer();
                    pcSignals.onReceiveAnswer.emit(
                        new RTCSessionDescription({
                            sdp: msg.sdp,
                            type: 'answer',
                        }),
                    );
                    break;
                case 'roster_update':
                    meeSignals.onRosterUpdate.emit(msg.participants);
                    break;

                default:
                    // TODO
                    break;
            }
        }),
    ];

    let detachPCSignals = [
        pcSignals.onConnectionStateChange.add(connectionState => {
            if (['connected', 'failed'].includes(connectionState)) {
                abortController?.signal.removeEventListener(
                    'abort',
                    handleAbort,
                );
            }
        }),
        pcSignals.onOffer.add(({sdp}) => {
            if (!sdp) {
                return;
            }

            socket.send({
                type: 'media_offer',
                sdp,
            });
        }),

        pcSignals.onIceCandidate.add(outgoingICECandidateQueue.enqueue),

        pcSignals.onRemoteStreams.add(streams => {
            logger.debug({streams}, 'Remote streams received');
            meeSignals.onRemoteStreams.emit(streams);
        }),
    ];

    const setStream = (stream: MediaStream) => {
        // for (const config of pc.getTransceiverConfigs()) {
        //     if (isMainConfig(config) && config.direction === 'sendonly') {
        //         const [track] =
        //             config.kind === 'video'
        //                 ? stream.getVideoTracks()
        //                 : stream.getAudioTracks();
        //         await config.syncTransceiver(pc.peer, {
        //             streams: stream ? [stream] : [],
        //             track: track ?? null,
        //             direction: 'sendonly',
        //         });
        //     }
        // }
        pcSignals.onOfferRequired.emit({
            stream,
            target: pc
                .getTransceiverConfigs()
                .flatMap(config =>
                    isMainConfig(config) && config.direction === 'sendonly'
                        ? [[config]]
                        : [],
                ),
        });
    };

    const cleanup = () => {
        detachSocketSignals.forEach(detach => detach());
        detachSocketSignals = [];

        detachPCSignals.forEach(detach => detach());
        detachPCSignals = [];
    };

    const close = () => {
        cleanup();
        if (pc.connectionState === 'closed') {
            return;
        }
        pc.close();
    };

    pcSignals.onOfferRequired.emit();

    return {
        get pc() {
            return pc;
        },
        setStream,
        close,
    };
};

export type Call = ReturnType<typeof createCall>;
