import {
    createSocketManager,
    isWebSocketClosedWithError,
    SocketCloseEventCode,
} from '@pexip/socket-manager';
import type {JoinMeeting} from '@pexip/mee-api';
import {createApi, GoneError, NotFoundError} from '@pexip/mee-api';
import type {Detach} from '@pexip/signal';
import type {TransceiverConfig, TransceiverInit} from '@pexip/peer-connection';

import type {Call} from './call';
import {createCall} from './call';
import {logger} from './logger';
import {
    MeetingFullError,
    ResourceUnavailableError,
    retriable,
    WebsocketError,
} from './utils';
import {MeeError} from './types';
import type {
    SocketManager,
    Mee,
    MeeConnectArgs,
    MeeSignals,
    SocketSignals,
} from './types';
import {MAX_RECONNECT_ATTEMPTS} from './constants';

/**
 * Creates MEE wrapper for the given `apiAddress` and `userID`
 *
 * @param args - Provides necessary information like `apiAddress` and `userId`
 * @returns Wrapper which encapsulates all interactions with MEE backend including WEBRTC
 */
export const createMee = ({
    meeSignals,
    socketSignals,
}: {
    meeSignals: MeeSignals;
    socketSignals: SocketSignals;
}): Mee => {
    const api = createApi();

    let socket: SocketManager;
    let call: Call | undefined;
    let detachSignals: Detach[] = [];
    let rejoinAttempt = 0;

    const connect = async (args: MeeConnectArgs) => {
        const {
            abortController,
            apiAddress,
            mediaInits,
            meetingId,
            participantId,
            participantSecret,
            retry,
        } = args;
        socket = createSocketManager(socketSignals);
        let meetingDetails: JoinMeeting | undefined;

        try {
            logger.debug({meetingId, mediaInits}, 'Connect');

            const _makeCall = () => {
                if (call) {
                    call.close();
                }
                call = createCall({
                    abortController,
                    mediaInits,
                    meeSignals,
                    socket,
                    socketSignals,
                });
            };

            const _connect = async (retry = false) => {
                const abortSignal = abortController?.signal;
                const join = () =>
                    api.join({
                        abortSignal,
                        apiAddress,
                        meetingId,
                        participantId,
                        participantSecret,
                    });

                const data =
                    meetingDetails ??
                    (await (retry ? retriable(join) : join())).data;
                meetingDetails = data;

                await socket.connect({
                    url: data.location,
                    abortController,
                });

                return data;
            };

            const _authenticate = async (data: JoinMeeting) => {
                const ref = socket.send({
                    type: 'authenticate',
                    token: data.token,
                    participant_id: participantId,
                });

                await new Promise<void>((resolve, reject) => {
                    const detach = socketSignals.onMessage.add(msg => {
                        if (msg.ref === ref) {
                            detach();

                            if (msg.type === 'success') {
                                return resolve();
                            }

                            if (msg.type === 'error') {
                                if (msg.error_type === 'resource_unavailable') {
                                    return msg.error_message ===
                                        'Meeting is full'
                                        ? reject(
                                              new MeetingFullError(
                                                  msg.error_message,
                                              ),
                                          )
                                        : reject(
                                              new ResourceUnavailableError(
                                                  msg.error_message ??
                                                      msg.error_type,
                                              ),
                                          );
                                }

                                return reject(
                                    new WebsocketError(
                                        msg.error_message ?? msg.error_type,
                                    ),
                                );
                            }
                            return reject(new WebsocketError(msg.type));
                        }
                    });
                });
            };

            await _authenticate(await _connect(retry));
            _makeCall();

            const _reconnect = async () => {
                try {
                    logger.debug('Reconnecting');
                    const _tryReconnect = async () => {
                        disconnect();
                        await _authenticate(await _connect(true));
                    };

                    meeSignals.onReconnecting.emit();
                    await retriable(_tryReconnect);
                    _makeCall();
                    meeSignals.onReconnected.emit();
                    logger.debug('Reconnected');
                } catch (error) {
                    logger.error('Reconnecting failed');
                    meeSignals.onError.emit(MeeError.RECONNECTION_FAILED);
                }
            };

            const _rejoin = async () => {
                try {
                    logger.debug('Rejoining');
                    meeSignals.onReconnecting.emit();
                    await retriable(() => connect({...args, retry: true}));
                    rejoinAttempt = 0;
                    meeSignals.onReconnected.emit();
                    logger.debug('Rejoined');
                } catch (error) {
                    logger.error('Rejoining failed');
                    meeSignals.onError.emit(MeeError.RECONNECTION_FAILED);
                }
            };

            detachSignals.push(
                socketSignals.onReconnected.add(async () => {
                    try {
                        logger.debug(
                            'Websocket reconnected. Try reauthenticating',
                        );
                        if (meetingDetails) {
                            meeSignals.onReconnecting.emit();
                            await _authenticate(meetingDetails);
                            _makeCall();
                            meeSignals.onReconnected.emit();
                        }
                    } catch (error) {
                        logger.error(
                            'Reauthenticating failed. Try reconnecting',
                        );
                        await _reconnect();
                    }
                }),
            );

            detachSignals.push(
                socketSignals.onDisconnected.add(async ({code}) => {
                    if (!isWebSocketClosedWithError(code)) {
                        return;
                    }

                    rejoinAttempt++;
                    if (rejoinAttempt < MAX_RECONNECT_ATTEMPTS) {
                        logger.error('Websocket disconnected. Try rejoining');
                        disconnect();
                        await _rejoin();
                    } else {
                        logger.error("Websocket disconnected. That's it.");
                    }
                }),
            );
        } catch (error) {
            if (error instanceof Error && error.name !== 'AbortError') {
                disconnect();

                if (error instanceof GoneError) {
                    meeSignals.onError.emit(MeeError.MEETING_EXPIRED);
                } else if (error instanceof NotFoundError) {
                    meeSignals.onError.emit(MeeError.MEETING_NOT_FOUND);
                } else if (error instanceof MeetingFullError) {
                    meeSignals.onError.emit(MeeError.MEETING_FULL);
                } else {
                    meeSignals.onError.emit(MeeError.CONNECTION_FAILED);
                }
                throw error;
            }
        }
    };

    const disconnect = () => {
        socket.disconnect(SocketCloseEventCode.NormalClosure);
        call?.close();

        detachSignals.forEach(detach => detach());
        detachSignals = [];
    };

    return {
        send: (...params: Parameters<typeof socket.send>) =>
            socket.send(...params),
        getTransceiverConfigs() {
            return call?.pc.getTransceiverConfigs() ?? [];
        },
        addConfig(initOrConfig: TransceiverInit | TransceiverConfig) {
            return call?.pc.addConfig(call?.pc.peer, initOrConfig);
        },
        setStream(stream: MediaStream) {
            call?.setStream(stream);
        },
        connect,
        disconnect,
    };
};
