import React, {createContext} from 'react';

import {
    createMee as createMeeSdk,
    createRecvTransceivers,
    createMeeSignals,
    createSocketSignals,
    isPresoVideo,
} from '@pexip/mee-sdk';
import type {
    MEEWebsocketMessageTypesUnion,
    Roster,
    RosterEntry,
} from '@pexip/mee-sdk';
import type {MediaController} from '@pexip/media';
import type {TransceiverConfig} from '@pexip/peer-connection';
import {createEventQueue} from '@pexip/peer-connection';
import {createSignal} from '@pexip/signal';
import {StreamQuality} from '@pexip/media-components';
import {stopMediaStream} from '@pexip/media-control';
import type {Participant} from '@pexip/mee-api';
import {createApi} from '@pexip/mee-api';

import {RECV_AUDIO_COUNT, RECV_VIDEO_COUNT} from '../constants';
import {config} from '../config';
import {logger} from '../logger';

import {mediaController} from './media.context';

export const meeSignals = createMeeSignals();
export const socketSignals = createSocketSignals();

export const onRemoteStreams = createSignal<MediaStream[]>({
    name: 'mee:onRemoteStreams',
});

export const onPresentation = createSignal<MediaStream | undefined>({
    name: 'mee:onPresentation',
});

const streamQualityToRid = (streamQuality: StreamQuality) => {
    switch (streamQuality) {
        case StreamQuality.High:
            return 'h';
        case StreamQuality.Low:
            return 'l';
        default:
            return 'm';
    }
};

const createMee = (mediaController: MediaController) => {
    const backendApi = createApi();
    const meeSdk = createMeeSdk({
        meeSignals,
        socketSignals,
    });

    let myParticipantId: string;
    const roster = new Map<string, RosterEntry>();
    // TODO: this seems unnecessary we just need to have a better structure of the onRemoteStreams signal
    const streamIdtoPId = new Map<string, string>();
    const requestedStreams = new Map<string, TransceiverConfig | undefined>();
    let presentationStream: MediaStream | undefined;

    const emitStreams = () => {
        onRemoteStreams.emit(
            Array.from(requestedStreams.entries()).flatMap(
                ([id, transceiverConfig]) => {
                    const remoteStreams =
                        transceiverConfig?.remoteStreams ?? [];
                    for (const remoteStream of remoteStreams) {
                        streamIdtoPId.set(remoteStream.id, id);
                    }
                    return remoteStreams;
                },
            ),
        );
    };
    const requestStreams = (pids: Roster) => {
        let shouldUpdate = false;
        for (const id of requestedStreams.keys()) {
            const [pId, nr] = id.split('-');
            if (pId && nr && !pids[pId]?.streams[nr]) {
                requestedStreams.delete(id);
                shouldUpdate = true;
            }
        }

        if (shouldUpdate) {
            emitStreams();
        }

        for (const [producerId, producer] of Object.entries(pids)) {
            for (const [streamId, stream] of Object.entries(producer.streams)) {
                // To simplify layout receive its own presentation
                if (
                    producerId === myParticipantId &&
                    (stream.semantic !== 'presentation' ||
                        (stream.semantic === 'presentation' &&
                            stream.type === 'audio'))
                ) {
                    continue;
                }
                const id = `${producerId}-${streamId}`;
                if (!requestedStreams.has(id)) {
                    // Add streamId to the Map as soon as possible so
                    // we are not running this code again as getting mid is async
                    requestedStreams.set(id, undefined);
                    const preferredRid = streamQualityToRid(
                        config.get('streamQuality'),
                    );
                    const ref = meeSdk.send({
                        type: 'request_stream',
                        producer_id: producerId,
                        stream_id: streamId,
                        rid:
                            stream.type === 'video' &&
                            stream.layers?.find(
                                // for testing simulcast we just pick the `medium` stream
                                // when we move this code to the app, user will model this
                                ({rid}) => rid === preferredRid,
                            )
                                ? preferredRid
                                : null,
                        //FIXME: some props should be optional in `RequestStream` type
                    } as MEEWebsocketMessageTypesUnion);

                    const detach = socketSignals.onMessage.add(msg => {
                        if (msg.ref !== ref) {
                            return;
                        }

                        if (msg.type === 'request_stream_response') {
                            const transceiverConfig = meeSdk
                                .getTransceiverConfigs()
                                .find(
                                    ({transceiver}) =>
                                        transceiver?.mid === msg.receive_mid,
                                );
                            if (transceiverConfig) {
                                requestedStreams.set(id, transceiverConfig);
                            }

                            emitStreams();
                        }

                        detach();
                    });
                }
            }
        }
    };

    const refreshStreams = () => {
        for (const [id, transceiverConfig] of requestedStreams.entries()) {
            const [producerId, streamId] = id.split('-');
            const mid = transceiverConfig?.transceiver?.mid;
            if (
                !producerId ||
                typeof streamId === 'undefined' ||
                typeof mid === 'undefined'
            ) {
                continue;
            }
            const stream = roster.get(producerId)?.streams[streamId];
            if (
                !transceiverConfig?.transceiver ||
                !stream ||
                stream.type !== 'video'
            ) {
                continue;
            }
            const preferredRid = streamQualityToRid(
                config.get('streamQuality'),
            );
            meeSdk.send({
                type: 'request_stream',
                producer_id: producerId,
                stream_id: streamId,
                rid: stream.layers?.find(
                    // for testing simulcast we just pick the `medium` stream
                    // when we move this code to the app, user will model this
                    ({rid}) => rid === preferredRid,
                )
                    ? preferredRid
                    : null,
                receive_mid: mid,
                //FIXME: some props should be optional in `RequestStream` type
            } as MEEWebsocketMessageTypesUnion);
        }
    };

    const connect = async ({
        meetingId,
        abortController,
    }: {
        meetingId: string;
        abortController?: AbortController;
    }) => {
        const _connect = async () => {
            const participants = () =>
                backendApi.participants({
                    apiAddress: `${config.get('apiAddress')}/api`,
                    abortSignal: abortController?.signal,
                    meetingId,
                });
            const res = await participants();

            const participant = res?.data as Participant & {
                crudAddress: string;
            };
            myParticipantId = participant.id;

            return meeSdk.connect({
                apiAddress: participant.crudAddress,
                participantId: participant.id,
                participantSecret: participant.participant_secret,
                meetingId,
                abortController,
                get mediaInits() {
                    const stream = mediaController.media.stream;
                    const [audioTrack] = stream?.getAudioTracks() ?? [];
                    const [videoTrack] = stream?.getVideoTracks() ?? [];
                    const {width, height} = videoTrack?.getSettings() ?? {};
                    logger.debug(
                        {stream, audioTrack, videoTrack, width, height},
                        'Media inits',
                    );
                    return [
                        {
                            content: 'main',
                            direction: 'sendonly',
                            kindOrTrack: audioTrack ?? 'audio',
                            streams: stream && audioTrack ? [stream] : [],
                        } as const,
                        {
                            content: 'main',
                            direction: 'sendonly',
                            kindOrTrack: videoTrack ?? 'video',
                            streams: stream && videoTrack ? [stream] : [],
                            sendEncodings:
                                width && height
                                    ? [
                                          {
                                              rid: 'l',
                                              scaleResolutionDownBy: 4.0,
                                              maxWidth: Math.trunc(width / 4),
                                              maxHeight: Math.trunc(height / 4),
                                          },
                                          {
                                              rid: 'm',
                                              scaleResolutionDownBy: 2.0,
                                              maxWidth: Math.trunc(width / 2),
                                              maxHeight: Math.trunc(height / 2),
                                          },
                                          {
                                              rid: 'h',
                                              maxWidth: width,
                                              maxHeight: height,
                                          },
                                      ]
                                    : undefined,
                        } as const,
                        ...createRecvTransceivers('audio', RECV_AUDIO_COUNT),
                        ...createRecvTransceivers('video', RECV_VIDEO_COUNT),
                    ];
                },
            });
        };
        await _connect();
    };

    const getPresentationStream = async () => {
        const stream = await navigator.mediaDevices.getDisplayMedia({
            audio: true,
            video: true,
        });
        stream.getVideoTracks().forEach(track => {
            // track.onended syntax doesn't seem to work
            track.addEventListener('ended', () => {
                logger.debug('Track ended. Stop presentation.');
                stopPresenting();
            });
        });
        onPresentation.emit(stream);
        return stream;
    };

    const stopPresentationStream = () => {
        if (presentationStream) {
            stopMediaStream(presentationStream);
            presentationStream = undefined;
            onPresentation.emit();
        }
    };

    const present = async () => {
        stopPresentationStream();
        try {
            presentationStream = await getPresentationStream();
            const [audioTrack] = presentationStream?.getAudioTracks() ?? [];
            const [videoTrack] = presentationStream?.getVideoTracks() ?? [];
            logger.debug(
                {
                    presentationStream,
                    audioTrack,
                    videoTrack,
                },
                'Present',
            );

            /**
             * Current way of starting presentation is to create dynamic
             * m-line when start presenting. There is no way to start with inactive line
             * because answer doesnt mirror `content` property so there is no way to
             * sync transceivers after negotiation.
             */
            meeSdk.addConfig({
                content: 'slides',
                direction: 'sendonly',
                kindOrTrack: videoTrack ?? 'video',
                streams:
                    presentationStream && videoTrack
                        ? [presentationStream]
                        : [],
            } as const);
        } catch (error) {
            logger.error({error}, 'Presenting failed');
        }
    };

    const stopPresenting = () => {
        stopPresentationStream();

        meeSdk.getTransceiverConfigs().forEach(config => {
            if (
                isPresoVideo(config) &&
                config?.transceiver?.direction !== 'stopped'
            ) {
                config.transceiver?.stop();
            }
        });
    };

    const streamRequestsQueue = createEventQueue(requestStreams);
    const releaseStreamRequestsBuffer = () => {
        streamRequestsQueue.buffering = false;
        const streamRequestFlushed = streamRequestsQueue.flush();
        logger.debug(
            {streamRequests: streamRequestFlushed},
            'release buffered stream requests',
        );
    };

    const _detachApiSignals = [
        meeSignals.onRosterUpdate.add(rosterEvent => {
            for (const [id, rosterEntry] of Object.entries(rosterEvent)) {
                roster.set(id, rosterEntry);
            }
            streamRequestsQueue.enqueue(rosterEvent);
        }),
        meeSignals.onRemoteStreams.add(() => {
            emitStreams();
        }),
        meeSignals.onError.add(error => {
            logger.error({error}, 'Received error');
        }),
        socketSignals.onReconnected.add(() => {
            streamRequestsQueue.buffering = true;
            requestedStreams.clear();
        }),
        socketSignals.onDisconnected.add(() => {
            streamRequestsQueue.buffering = true;
            requestedStreams.clear();
        }),
        socketSignals.onMessage.add(({type}) => {
            if (type === 'media_offer') {
                releaseStreamRequestsBuffer();
            }
        }),
    ];

    return {
        create: (args: {abortController?: AbortController}) =>
            backendApi.create({
                ...args,
                apiAddress: `${config.get('apiAddress')}/api`,
            }),
        connect,
        disconnect: () => {
            mediaController.media.release().catch((error: unknown) => {
                logger.error({error}, `GUM cleanup failed`);
            });
            meeSdk.disconnect();
            stopPresenting();
        },
        setStream: meeSdk.setStream,
        getPId(sId: string) {
            const id = streamIdtoPId.get(sId);
            const [producerId, streamId] = (id ?? '').split('-');
            if (!producerId || !streamId) {
                return [undefined, undefined] as const;
            }
            const rosterEntry = roster.get(producerId);
            const stream = rosterEntry?.streams[streamId];
            return [producerId, stream] as const;
        },
        refreshStreams,
        present,
        stopPresenting,
        get myParticipantId() {
            return myParticipantId;
        },
    };
};

const mee = createMee(mediaController);

export const MeeContext = createContext<
    ReturnType<typeof createMee> | undefined
>(undefined);

export const MeeProvider: React.FC<React.PropsWithChildren> = ({children}) => (
    <MeeContext.Provider value={mee}>{children}</MeeContext.Provider>
);
