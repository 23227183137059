import React from 'react';

import {useMobileDevice} from '@pexip/components';
import {
    PreflightMediaControls,
    useAnalyzer,
    useDeviceErrorMessage,
    useDeviceErrorMessageState,
    useDeviceStatusInfo,
} from '@pexip/media-components';
import type {UserMediaStatus} from '@pexip/media';
import {isInitialPermissionsNotGranted} from '@pexip/media';
import type {BlockedBrowserPermissionsInfoType} from '@pexip/media-components/src/types';

import {
    MediaContext,
    toggleAudioMuted,
    toggleVideoMuted,
    useAudioMuteState,
    useLocalMedia,
    useVideoMuteState,
} from '../contexts/media.context';
import {useAssertedContext} from '../hooks/useAssertedContext';

import {AudioMeter} from './AudioMeter.viewModel';

export type SelfviewControlType =
    | 'express'
    | 'step-by-step'
    | 'in-meeting'
    | 'settings';

export const SelfviewControls: React.FC<{
    streamStatus: UserMediaStatus;
    setShowHelpVideo: React.Dispatch<React.SetStateAction<boolean>>;
    permissionInfoType: BlockedBrowserPermissionsInfoType;
}> = ({streamStatus, setShowHelpVideo, permissionInfoType}) => {
    const isMobileDevice = useMobileDevice();
    const m = useAssertedContext(MediaContext);
    const media = useLocalMedia(() => m.media);
    const {audioMuted, audioUnavailable} = useAudioMuteState(media);
    const {videoMuted, videoUnavailable} = useVideoMuteState(media);

    const inputStatusInfo = useDeviceStatusInfo(streamStatus, {
        audio: Boolean(media.constraints?.audio),
        video: Boolean(media.constraints?.video),
    });
    const analyzer = useAnalyzer(media);
    const shouldShowVideoStatus =
        streamStatus && isInitialPermissionsNotGranted(streamStatus);

    const toggleAudioAndPersist = () => {
        if (audioUnavailable) {
            return;
        }
        toggleAudioMuted(true);
    };

    const toggleVideoAndPersist = () => {
        if (videoUnavailable) {
            return;
        }
        toggleVideoMuted(true);
    };

    const {
        videoInputError,
        audioInputError,
        setVideoInputError,
        setAudioInputError,
    } = useDeviceErrorMessageState();

    useDeviceErrorMessage({
        setAudioInputError,
        setVideoInputError,
        streamStatus,
        requested: {
            audio: Boolean(media.constraints?.audio),
            video: Boolean(media.constraints?.video),
        },
    });

    return (
        <PreflightMediaControls
            isMobileDevice={isMobileDevice}
            shouldCenter
            inputStatusInfo={inputStatusInfo}
            isAudioInputMuted={audioMuted}
            isAudioInputUnavailable={audioUnavailable}
            isVideoInputMuted={videoMuted}
            isVideoInputUnavailable={videoUnavailable}
            requestMediaPermissions={() =>
                m.getUserMedia({audio: true, video: true})
            }
            streamStatus={streamStatus}
            toggleAudioInput={toggleAudioAndPersist}
            toggleVideoInput={toggleVideoAndPersist}
            shouldShowVideoStatus={shouldShowVideoStatus}
            audioInputControl={
                analyzer && (
                    <AudioMeter
                        analyzer={analyzer}
                        isAudioInputMuted={audioMuted}
                        streamStatus={streamStatus}
                        onClick={toggleAudioAndPersist}
                    />
                )
            }
            deviceError={videoInputError || audioInputError}
            setShowHelpVideo={setShowHelpVideo}
            permissionInfoType={permissionInfoType}
        />
    );
};
