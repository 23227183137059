import React, {useCallback, useEffect, useRef, useState} from 'react';

import {Audio} from '@pexip/components';
import type {Roster} from '@pexip/mee-sdk';
import {
    AbortReason,
    GoneError,
    MeetingFullError,
    NotFoundError,
} from '@pexip/mee-sdk';
import {findCurrentAudioOutputId} from '@pexip/media-control';
import {NetworkAlert, useNetworkState} from '@pexip/media-components';

import {VideoGrid} from '../views/VideoGrid/VideoGrid.view';
import type {Layout} from '../types';
import {logger} from '../logger';
import {MeeContext, meeSignals, onRemoteStreams} from '../contexts/mee.context';
import {
    MediaContext,
    mediaSignals,
    useDevices,
} from '../contexts/media.context';
import {useConfig} from '../contexts/config.context';
import {TestId} from '../../test/testIds';
import {useAssertedContext} from '../hooks/useAssertedContext';
import {VideoGridItem} from '../views/VideoGridItem/VideoGridItem.view';
import {FullscreenText} from '../views/FullscreenText/FullscreenText.view';
import {withHeader} from '../hocs/withHeader';

import {LocalStream} from './LocalStream.viewModel';
import {Footer} from './Footer.viewModel';

const hasVideo = (stream: MediaStream) => stream.getVideoTracks().length;
const hasAudio = (stream: MediaStream) => stream.getAudioTracks().length;

export const InMeeting: React.FC<{meetingId: string; leave: () => void}> =
    withHeader(({meetingId, leave}) => {
        const mee = useAssertedContext(MeeContext);
        const media = useAssertedContext(MediaContext);

        const [showNames] = useConfig('nameLabels');

        const [error, setError] = useState<Error>();
        const [recvVideoStreams, setRevcVideoStreams] = useState<MediaStream[]>(
            [],
        );
        const [recvAudioStreams, setRevcAudioStreams] = useState<MediaStream[]>(
            [],
        );
        const [layout, setLayout] = useState<Layout>('ac');
        const [roster, setRoster] = useState<Roster>({});

        const [selectedAudioOutput] = useConfig('audioOutput');
        const devices = useDevices(() => media.devices);

        const networkState = useNetworkState(
            meeSignals.onReconnecting,
            meeSignals.onReconnected,
        );

        const selectedAudioOutputDeviceId = findCurrentAudioOutputId(
            selectedAudioOutput,
            devices,
        );

        const allParticipantsIds = Object.keys(roster);
        const participantsIds = allParticipantsIds.filter(
            id => id !== mee.myParticipantId,
        );
        const participantsWithMediaUuids = participantsIds.filter(
            id => Object.keys(roster[id]?.streams ?? {}).length > 0,
        );

        const draggedId = useRef<string>();

        useEffect(() => {
            const abortController = new AbortController();
            mee.connect({
                meetingId,
                abortController,
            }).catch(error => {
                setError(error);
                logger.error(
                    {
                        error: error instanceof Error && error.message,
                    },
                    'Error connecting to the meeting',
                );
            });
            return () => {
                abortController.abort(AbortReason.Close);
                mee.disconnect();
            };
        }, [mee, meetingId]);

        useEffect(
            () =>
                onRemoteStreams.add(streams => {
                    setRevcAudioStreams(streams.filter(hasAudio));

                    setRevcVideoStreams(prevRecvVideoStreams => {
                        const allStreams = streams.filter(hasVideo);
                        const currentDiff = prevRecvVideoStreams.filter(
                            stream => allStreams.includes(stream),
                        );
                        const newStreams = allStreams.filter(
                            stream => !currentDiff.includes(stream),
                        );

                        return [...currentDiff, ...newStreams];
                    });
                }),
            [],
        );

        useEffect(() => meeSignals.onRosterUpdate.add(setRoster), []);

        useEffect(
            () =>
                mediaSignals.onMediaChanged.add(({stream}) => {
                    if (stream) {
                        logger.debug({stream}, 'setStream');
                        mee.setStream(stream);
                    }
                }),
            [mee],
        );

        const getError = useCallback((error: Error | undefined) => {
            if (error instanceof GoneError) {
                return 'Meeting expired';
            } else if (error instanceof NotFoundError) {
                return 'Meeting not found';
            } else if (error instanceof MeetingFullError) {
                return 'Meeting is full';
            } else {
                return 'Error connecting to the meeting...';
            }
        }, []);

        if (error) {
            return <FullscreenText>{getError(error)}</FullscreenText>;
        }

        const handleDragStart =
            (stream: MediaStream) => (e: React.DragEvent<HTMLVideoElement>) => {
                draggedId.current = stream.id;

                e.currentTarget.blur();
                if (e.dataTransfer !== undefined) {
                    e.dataTransfer.effectAllowed = 'move';
                    e.dataTransfer.dropEffect = 'move';
                }
            };

        const handleDragOver =
            (stream: MediaStream, i: number) =>
            (e: React.DragEvent<HTMLVideoElement>) => {
                e.preventDefault();
                if (draggedId.current === stream.id) {
                    return;
                }

                setRevcVideoStreams(prevRecvVideoStreams => {
                    if (!draggedId.current) {
                        return prevRecvVideoStreams;
                    }

                    const draggedElId = prevRecvVideoStreams.findIndex(
                        stream => stream.id === draggedId.current,
                    );
                    const dragged = prevRecvVideoStreams[draggedElId];

                    const current = prevRecvVideoStreams[i];
                    if (!dragged || !current) {
                        return prevRecvVideoStreams;
                    }

                    prevRecvVideoStreams[draggedElId] = current;
                    prevRecvVideoStreams[i] = dragged;
                    return [...prevRecvVideoStreams];
                });
            };

        const handleDragEnd = (e: React.DragEvent<HTMLVideoElement>) => {
            e.preventDefault();
            draggedId.current = undefined;
        };

        const handleClick = (stream: MediaStream, i: number) => () => {
            if (i == 0 && layout == 'spotlight') {
                setLayout('ac');
                return;
            }

            setRevcVideoStreams(prevRecvVideoStreams => {
                const pin = prevRecvVideoStreams.find(
                    ({id}) => id === stream.id,
                );
                if (!pin) {
                    return prevRecvVideoStreams;
                }

                return [
                    pin,
                    ...prevRecvVideoStreams.filter(({id}) => id !== stream.id),
                ];
            });

            if (layout === 'ac') {
                setLayout('spotlight');
            }
        };

        const getLayout = () => {
            if (participantsIds.length === 0) {
                return (
                    <FullscreenText data-testid={TestId.TextWaitingForOthers}>
                        Waiting for others to join...
                    </FullscreenText>
                );
            }

            if (participantsWithMediaUuids.length === 0) {
                return (
                    <FullscreenText>
                        Waiting for others to share media...
                    </FullscreenText>
                );
            }

            return (
                <>
                    <VideoGrid layout={layout}>
                        {recvVideoStreams.map((stream, i) => {
                            const [producerId, s] = mee.getPId(stream.id);
                            return (
                                <VideoGridItem
                                    key={stream.id}
                                    id={producerId}
                                    isPresentation={
                                        s?.semantic === 'presentation'
                                    }
                                    srcObject={stream}
                                    showName={showNames}
                                    onClick={handleClick(stream, i)}
                                    onDragStart={handleDragStart(stream)}
                                    onDragOver={handleDragOver(stream, i)}
                                    onDragEnd={handleDragEnd}
                                />
                            );
                        })}
                    </VideoGrid>
                    {recvAudioStreams.map(stream => (
                        <Audio
                            key={stream.id}
                            srcObject={stream}
                            autoPlay
                            sinkId={selectedAudioOutputDeviceId}
                        />
                    ))}
                </>
            );
        };
        return (
            <>
                <NetworkAlert networkState={networkState} />
                {getLayout()}
                <LocalStream />
                <Footer leave={leave} />
            </>
        );
    }, 'in-meeting');
