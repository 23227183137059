import {logger} from './logger';
import {ContentType, HEADER} from './utils';
import type * as types from './api.types';

export class BadRequestError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'BadRequestError';
    }
}

export class UnauthorizedError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'UnauthorizedError';
    }
}

export class ForbiddenError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ForbiddenError';
    }
}

export class NotFoundError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'NotFoundError';
    }
}

export class GoneError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'GoneError';
    }
}

export class ServiceUnavailableError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ServiceUnavailableError';
    }
}

export class InternalServerError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'InternalServerError';
    }
}
export class NotModifiedError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'NotModifiedError';
    }
}

export class UnknownError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'UnknownError';
    }
}

export type BadRequest =
    types.components['responses']['BadRequest']['content']['application/json'];

export type Unauthorized =
    types.components['responses']['Unauthorized']['content']['application/json'];

export type Forbidden =
    types.components['responses']['Forbidden']['content']['application/json'];

export type NotFound =
    types.components['responses']['NotFound']['content']['application/json'];

export type Gone =
    types.components['responses']['Gone']['content']['application/json'];

export type ServiceUnavailable =
    types.components['responses']['ServiceUnavailable']['content']['application/json'];

export type Token = types.components['schemas']['AccessToken'];
export type Meeting = types.components['schemas']['Meeting'];
export type Participant = types.components['schemas']['ParticipantWithSecret'];
export type JoinMeeting = types.components['schemas']['JoinDetails'];

export interface Status<T> {
    status: number;
    data: T;
}

export interface Api {
    /**
     * Creates a new meeting via CRUD
     *
     * @returns obj with `access_token`
     */
    token(args: {
        abortSignal?: AbortSignal;
        token: string;
        apiAddress: string;
    }): Promise<Status<Token>>;

    /**
     * Creates a new meeting via CRUD
     *
     * @returns meeting id
     */
    create(args: {
        accessToken?: string;
        abortSignal?: AbortSignal;
        apiAddress: string;
    }): Promise<Status<Meeting>>;

    /**
     * Creates `participant_id` and `participant_secret` for the given `meetingId`
     */
    participants(args: {
        accessToken?: string;
        abortSignal?: AbortSignal;
        meetingId: string;
        apiAddress: string;
    }): Promise<Status<Participant>>;

    /**
     * Joins a meeting for particular `participantId` and `participantSecret`
     *
     * @returns `location` path to be able to connect to WebSocket for all the meeting updates
     */
    join(args: {
        abortSignal?: AbortSignal;
        meetingId: string;
        participantId: string;
        participantSecret: string;
        apiAddress: string;
    }): Promise<Status<JoinMeeting>>;

    /**
     * Terminates meeting with the specified `meetingId`
     */
    terminate(args: {
        accessToken?: string;
        abortSignal?: AbortSignal;
        meetingId: string;
        apiAddress: string;
    }): Promise<Status<unknown>>;
}

/**
 * Creates MEE api wrapper for the given `apiAddress` and `token`
 *
 * @privateRemarks  This will be generated from OpenApi schema
 *
 * @returns Api wrapper with all the main CRUD fns
 */
export const createApi = (): Api => {
    const getPath = (apiAddress: string) => (path: string) =>
        `${apiAddress}/v1/meetings${path}`;

    const fetcher = async <T>(...args: Parameters<typeof fetch>) => {
        const [path, init] = args;
        const printableUrl = typeof path === 'string' ? path : '';
        logger.debug(`Fetching ${printableUrl}`);
        const res = await fetch(path, init);
        const data = (await res.json()) as object;
        if ('token' in data && typeof data.token === 'string') {
            logger.redact(data.token);
        }
        if (
            'participant_secret' in data &&
            typeof data.participant_secret === 'string'
        ) {
            logger.redact(data.participant_secret);
        }
        logger.debug({res, data}, `Data for ${printableUrl}`);
        return {status: res.status, data} as T;
    };

    const token = async ({
        abortSignal,
        apiAddress,
        token,
    }: {
        abortSignal?: AbortSignal;
        apiAddress: string;
        token: string;
    }) => {
        const {data, status} = await fetcher<
            | {status: 200; data: Token}
            | {status: 400; data: BadRequest}
            | {status: 500; data: ServiceUnavailable}
        >(`${apiAddress}/oauth/token`, {
            method: 'POST',
            body: new URLSearchParams({
                grant_type: 'client_credentials',
                client_assertion_type:
                    'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',
                client_assertion: token,
            }),
            signal: abortSignal,
            headers: {
                [HEADER.CONTENT_TYPE]: ContentType.FORM_URLENCODED,
                [HEADER.ACCEPT]: ContentType.JSON,
            },
        });

        switch (status) {
            case 200:
                return {data, status};

            case 400:
                throw new BadRequestError(data.reason);

            case 500:
                throw new InternalServerError(data.reason);

            default:
                throw new UnknownError('');
        }
    };

    const create = async ({
        abortSignal,
        apiAddress,
        accessToken,
    }: {
        abortSignal?: AbortSignal;
        apiAddress: string;
        accessToken?: string;
    }) => {
        const {data, status} = await fetcher<
            | {status: 200; data: Meeting}
            | {status: 400; data: BadRequest}
            | {status: 401; data: Unauthorized}
            | {status: 403; data: Forbidden}
            | {status: 404; data: NotFound}
            | {status: 500; data: ServiceUnavailable}
        >(getPath(apiAddress)(''), {
            method: 'POST',
            body: JSON.stringify({}),
            signal: abortSignal,
            headers: {
                [HEADER.CONTENT_TYPE]: ContentType.JSON,
                ...(accessToken && {[HEADER.AUTHORIZATION]: accessToken}),
            },
        });

        switch (status) {
            case 200:
                return {data, status};

            case 400:
                throw new BadRequestError(data.reason);

            case 401:
                throw new UnauthorizedError(data.reason);

            case 403:
                throw new ForbiddenError(data.reason);

            case 404:
                throw new NotFoundError(data.reason);

            case 500:
                throw new InternalServerError(data.reason);

            default:
                throw new UnknownError('');
        }
    };

    const participants = async ({
        abortSignal,
        meetingId,
        apiAddress,
        accessToken,
    }: {
        abortSignal?: AbortSignal;
        meetingId: string;
        apiAddress: string;
        accessToken?: string;
    }) => {
        const {data, status} = await fetcher<
            | {status: 200; data: Participant}
            | {status: 400; data: BadRequest}
            | {status: 401; data: Unauthorized}
            | {status: 403; data: Forbidden}
            | {status: 404; data: NotFound}
            | {status: 500; data: ServiceUnavailable}
        >(getPath(apiAddress)(`/${meetingId}/participants`), {
            method: 'POST',
            body: JSON.stringify({}),
            signal: abortSignal,
            headers: {
                [HEADER.CONTENT_TYPE]: ContentType.JSON,
                ...(accessToken && {[HEADER.AUTHORIZATION]: accessToken}),
            },
        });

        switch (status) {
            case 200:
                return {data, status};

            case 400:
                throw new BadRequestError(data.reason);

            case 401:
                throw new UnauthorizedError(data.reason);

            case 403:
                throw new ForbiddenError(data.reason);

            case 404:
                throw new NotFoundError(data.reason);

            case 500:
                throw new InternalServerError(data.reason);

            default:
                throw new UnknownError('');
        }
    };

    const join = async ({
        abortSignal,
        apiAddress,
        meetingId,
        participantId,
        participantSecret,
    }: {
        abortSignal?: AbortSignal;
        apiAddress: string;
        meetingId: string;
        participantId: string;
        participantSecret: string;
    }) => {
        const {data, status} = await fetcher<
            | {status: 200; data: JoinMeeting}
            | {status: 400; data: BadRequest}
            | {status: 404; data: NotFound}
            | {status: 410; data: Gone}
            | {status: 503; data: ServiceUnavailable}
            | {status: 500; data: ServiceUnavailable}
        >(getPath(apiAddress)(`/${meetingId}/join`), {
            method: 'POST',
            body: JSON.stringify({
                participant_id: participantId,
                participant_secret: participantSecret,
            }),
            signal: abortSignal,
            headers: {
                [HEADER.CONTENT_TYPE]: ContentType.JSON,
            },
        });

        switch (status) {
            case 200:
                return {data, status};

            case 400:
                throw new BadRequestError(data.reason);

            case 404:
                throw new NotFoundError(data.reason);

            case 410:
                throw new GoneError(data.reason);

            case 500:
                throw new InternalServerError(data.reason);

            case 503:
                throw new ServiceUnavailableError(data.reason);

            default:
                throw new UnknownError('');
        }
    };

    const terminate = async ({
        abortSignal,
        apiAddress,
        meetingId,
        accessToken,
    }: {
        abortSignal?: AbortSignal;
        apiAddress: string;
        meetingId: string;
        accessToken?: string;
    }) => {
        const {data, status} = await fetcher<
            | {status: 200; data: Meeting}
            | {status: 304; data: undefined}
            | {status: 401; data: Unauthorized}
            | {status: 403; data: Forbidden}
            | {status: 404; data: NotFound}
            | {status: 500; data: ServiceUnavailable}
        >(getPath(apiAddress)(`/${meetingId}`), {
            method: 'DELETE',
            signal: abortSignal,
            headers: {
                ...(accessToken && {[HEADER.AUTHORIZATION]: accessToken}),
            },
        });

        switch (status) {
            case 200:
                return {data, status};

            case 304:
                throw new NotFoundError('');

            case 401:
                throw new UnauthorizedError(data.reason);

            case 403:
                throw new ForbiddenError(data.reason);

            case 404:
                throw new NotFoundError(data.reason);

            default:
                throw new UnknownError('');
        }
    };

    return {
        token,
        create,
        join,
        participants,
        terminate,
    };
};

const ACCESS_EXP_BUFFER = 60_000;
export const withToken =
    (createAndSignJWT: () => string, apiAddress: string) => (api: Api) => {
        let token: Token;
        let tokenReceivedDate: number;

        const isTokenValid = () =>
            Boolean(
                tokenReceivedDate &&
                    token?.expires_in &&
                    tokenReceivedDate +
                        token.expires_in * 1000 -
                        ACCESS_EXP_BUFFER >
                        Date.now(),
            );

        const getAccessToken = () =>
            `${token?.token_type} ${token?.access_token}`;

        const getToken = async () => {
            if (isTokenValid()) {
                return getAccessToken();
            }

            const res = await api.token({
                token: createAndSignJWT(),
                apiAddress,
            });

            token = res.data;
            tokenReceivedDate = Date.now();
            return getAccessToken();
        };

        return {
            create: async () =>
                api.create({
                    accessToken: await getToken(),
                    apiAddress,
                }),
            participants: async ({meetingId}: {meetingId: string}) =>
                api.participants({
                    meetingId,
                    accessToken: await getToken(),
                    apiAddress,
                }),
        };
    };
