import React from 'react';
import {useTranslation} from 'react-i18next';

import {AboutText, Panel, ThemeProvider} from '@pexip/components';
import {BreakoutRoomsPanelHeader as PanelHeader} from '@pexip/media-components';

import {APP_VERSION, DIRTY, ENV, VERSION} from '../../constants';

export const About: React.FC<{
    onBackClick: () => void;
}> = ({onBackClick}) => {
    const {t} = useTranslation();

    return (
        <Panel
            colorScheme="light"
            style={{width: 380}}
            headerPadding="none"
            headerContent={
                <ThemeProvider colorScheme="light">
                    <PanelHeader
                        title={`${t('settings.about', 'About')}`}
                        onBackClick={onBackClick}
                    />
                </ThemeProvider>
            }
        >
            <ThemeProvider colorScheme="light">
                <AboutText
                    className=""
                    headingText=""
                    spacing="none"
                    version={`${APP_VERSION}+${VERSION}${DIRTY} ${ENV}`}
                />
            </ThemeProvider>
        </Panel>
    );
};
