import React from 'react';

import type {AnalyzerNodeInit} from '@pexip/media-processor';
import type {UserMediaStatus} from '@pexip/media';
import {
    AudioMeterButton,
    useAudioMeter,
    useInputsState,
    useDeviceStatusInfo,
} from '@pexip/media-components';

export const AudioMeter: React.FC<{
    analyzer: AnalyzerNodeInit;
    level?: number;
    isAudioInputMuted: boolean;
    onClick: () => void;
    streamStatus: UserMediaStatus | undefined;
}> = ({analyzer, level, isAudioInputMuted, onClick, streamStatus}) => {
    const inputStatusInfo = useDeviceStatusInfo(streamStatus, {
        audio: true,
        video: true,
    });

    const {audioProps} = useInputsState({
        isAudioInputMuted,
        isVideoInputMuted: false,
        inputStatusInfo,
        iconSize: 'medium',
    });

    const max = useAudioMeter(analyzer);

    return (
        <AudioMeterButton
            {...audioProps}
            isActive={audioProps.isActive}
            level={level ? level : max}
            onClick={onClick}
        />
    );
};
