import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Menu} from '@pexip/components';

import {SettingsMenu} from '../../views/SettingsMenu/SettingsMenu.view';
import {rootLogger} from '../../logger';
import type {Step} from '../../types';

import {VideoAndSound} from './VideoAndSound.viewModel';
import {Quality} from './Quality.viewModel';
import {MeetingSettings} from './MeetingSettings.viewModel';
import {About} from './About.viewModel';

type SettingsStep =
    | 'settings'
    | 'video-and-sound'
    | 'quality'
    | 'meeting-settings'
    | 'about';

export const Settings: React.FC<{step: Step}> = ({step}) => {
    const [settingsStep, setSettingsStep] = useState<SettingsStep>('settings');

    const handleBack = useCallback(() => {
        setSettingsStep('settings');
    }, []);

    return (
        <SettingsMenu
            onClose={() => setSettingsStep('settings')}
            content={close => (
                <SettingsHandler
                    close={close}
                    onBackClick={handleBack}
                    step={step}
                    setSettingsStep={setSettingsStep}
                    settingsStep={settingsStep}
                />
            )}
        />
    );
};

export const SettingsHandler: React.FC<{
    step: Step;
    settingsStep: SettingsStep;
    close: (e: React.SyntheticEvent<HTMLElement>) => void;
    setSettingsStep: (settings: SettingsStep) => void;
    onBackClick: () => void;
}> = ({close, step, settingsStep, setSettingsStep, onBackClick}) => {
    const {t} = useTranslation();

    switch (settingsStep) {
        case 'video-and-sound':
            return <VideoAndSound onBackClick={onBackClick} close={close} />;

        case 'quality':
            return <Quality onBackClick={onBackClick} />;

        case 'meeting-settings':
            return <MeetingSettings onBackClick={onBackClick} />;

        case 'about':
            return <About onBackClick={onBackClick} />;

        default:
            return (
                <Menu
                    menuContent={[
                        [
                            {
                                label: t(
                                    'settings.video-and-sound',
                                    'Video and Sound',
                                ),
                                clickAction: () =>
                                    setSettingsStep('video-and-sound'),
                                hiddenIn: ['preflight', 'post-meeting'],
                            },
                            {
                                label: t('settings.quality', 'Quality'),
                                clickAction: () => setSettingsStep('quality'),
                                hiddenIn: ['in-meeting'],
                            },
                            {
                                label: t(
                                    'settings.meeting-settings',
                                    'Meeting settings',
                                ),
                                clickAction: () =>
                                    setSettingsStep('meeting-settings'),
                            },
                            {
                                label: t('settings.about', 'About'),
                                clickAction: () => setSettingsStep('about'),
                            },
                            {
                                label: t(
                                    'settings.download-logs',
                                    'Download logs',
                                ),
                                clickAction: () => rootLogger.downloadLog(),
                            },
                        ].flatMap(({hiddenIn, ...menu}) =>
                            hiddenIn?.includes(step) ? [] : [menu],
                        ),
                    ]}
                />
            );
    }
};
