import React, {useCallback, useEffect} from 'react';

import {FullscreenLayout, ThemeProvider} from '@pexip/components';
import {
    BlockedBrowserPermissionsInfoType,
    DevicesSelection,
    JoinMeetingButton,
    PreflightSelfview,
    onDeviceSelectChange,
    useDeviceErrorMessage,
    useDeviceErrorMessageState,
    useMediaInputs,
    useSelectAudioOutput,
} from '@pexip/media-components';
import type {PreviewInput} from '@pexip/media';
import {isInitialPermissions} from '@pexip/media';
import type {MediaDeviceInfoLike} from '@pexip/media-control';
import {toMediaDeviceInfo} from '@pexip/media-control';
import {noop} from '@pexip/utils';

import {
    MediaContext,
    useDevices,
    useLocalMedia,
    useStreamStatus,
} from '../contexts/media.context';
import {logger} from '../logger';
import {ConfigContext, useConfig} from '../contexts/config.context';
import {NAMESPACE} from '../constants';
import {TestId} from '../../test/testIds';
import {useAssertedContext} from '../hooks/useAssertedContext';
import {withHeader} from '../hocs/withHeader';
import {PreflightBox} from '../views/PreflightBox/PreflightBox.view';

import {SelfviewControls} from './SelfviewControls.viewModel';

export const Preflight: React.FC<{call: () => void}> = withHeader(({call}) => {
    const m = useAssertedContext(MediaContext);
    const config = useAssertedContext(ConfigContext);

    const media = useLocalMedia(() => m.media);
    const devices = useDevices(() => m.devices);
    const streamStatus = useStreamStatus(() => m.media.status);

    const requestedAudio = Boolean(m.media.constraints?.audio);
    const requestedVideo = Boolean(m.media.constraints?.video);

    const {selectedAudioInput, selectedVideoInput} = useMediaInputs(m.media);
    const [savedAudioOutput, setSavedAudioOutput] = useConfig('audioOutput');
    const [isVideoMediaMuted] = useConfig('videoInputMuted');

    const {selectedAudioOutput} = useSelectAudioOutput(
        devices,
        savedAudioOutput,
    );
    const {
        videoInputError,
        setVideoInputError,
        audioInputError,
        setAudioInputError,
    } = useDeviceErrorMessageState();
    useDeviceErrorMessage({
        setAudioInputError,
        setVideoInputError,
        streamStatus,
        requested: {audio: requestedAudio, video: requestedVideo},
    });

    const onVideoInputChange = onDeviceSelectChange(
        setVideoInputError,
        (input: PreviewInput) => {
            const {videoInput: prevVideoInput} = m.media;
            if (
                !input ||
                isInitialPermissions(m.media.status) ||
                input?.deviceId === prevVideoInput?.deviceId
            ) {
                return;
            }
            const info = toMediaDeviceInfo(input);
            const constraints = {
                audio: selectedAudioInput,
                video: {device: {exact: info}},
            };
            logger.debug(constraints, 'Save VideoInput');
            config.set({key: 'videoInput', value: info, persist: true});

            m.getUserMedia(constraints);
        },
    );

    const onAudioInputChange = onDeviceSelectChange(
        setAudioInputError,
        (input: PreviewInput) => {
            const {audioInput: prevAudioInput} = m.media;
            if (
                !input ||
                isInitialPermissions(m.media.status) ||
                input?.deviceId === prevAudioInput?.deviceId
            ) {
                return;
            }
            const info = toMediaDeviceInfo(input);
            const constraints = {
                audio: {device: {exact: info}},
                video: selectedVideoInput,
            };
            logger.debug(constraints, 'Save AudioInput');
            config.set({key: 'audioInput', value: info, persist: true});

            m.getUserMedia(constraints);
        },
    );

    const onAudioOutputChange = useCallback(
        (device: MediaDeviceInfoLike) => {
            setSavedAudioOutput(device, true);
        },
        [setSavedAudioOutput],
    );

    useEffect(() => {
        m.getUserMedia({audio: true, video: true});
    }, [m]);

    return (
        <FullscreenLayout>
            <ThemeProvider colorScheme="light">
                <PreflightBox>
                    <ThemeProvider colorScheme="dark">
                        <PreflightSelfview
                            streamStatus={streamStatus}
                            username={NAMESPACE}
                            isVideoInputMuted={isVideoMediaMuted}
                            shouldShowBorder={false}
                            localMediaStream={media?.stream}
                            preflightControls={
                                <SelfviewControls
                                    streamStatus={streamStatus}
                                    setShowHelpVideo={noop}
                                    permissionInfoType={
                                        BlockedBrowserPermissionsInfoType.VOID
                                    }
                                />
                            }
                        />
                    </ThemeProvider>
                    <DevicesSelection
                        devices={devices}
                        audioInputError={audioInputError}
                        videoInputError={videoInputError}
                        onVideoInputChange={onVideoInputChange}
                        onAudioInputChange={onAudioInputChange}
                        onAudioOutputChange={onAudioOutputChange}
                        requestedAudio={requestedAudio}
                        requestedVideo={requestedVideo}
                        videoInput={selectedVideoInput}
                        audioOutput={selectedAudioOutput}
                        audioInput={selectedAudioInput}
                        setShowHelpVideo={noop}
                    />
                    <JoinMeetingButton
                        onClick={call}
                        isDisabled={!media.stream}
                        data-testid={TestId.ButtonJoin}
                    />
                </PreflightBox>
            </ThemeProvider>
        </FullscreenLayout>
    );
}, 'preflight');
